.lens-main {
	margin: 20px 10%;
}
.table {
	margin: 10px 0%;
	/* background-color: darkslategray;
	color: white; */
}

.heading {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.heading > p {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	display: grid;
	place-content: center;
	padding: 10px;
	font-weight: bold;
	font-size: 1.3em;
	color: #0c8b23;
}
.heading > p:last-child {
	border-right: 1px solid black;
}
.row {
	margin: 20px 0;
}
.row:first-of-type {
	/* margin: 0; */
}
.series {
	background-color: #0c8b23;
	/* border: 1px solid black; */
	border-radius: 5px 5px 0 0;
	padding: 10px;
	color: white;
}

.series-models {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.model-names,
.model-descriptions {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.model-image > img {
	width: 60px;
}
.model-image {
	background-color: white;
}
.model-names > p,
.model-descriptions > p,
.model-image {
	height: 100%;
	display: grid;
	place-content: center;
	padding: 10px;

	border-left: 1px solid black;
	border-bottom: 1px solid black;
}
.model-names > p:first-child,
.model-descriptions > p,
.model-image {
	border-top: 1px solid black;
}
.model-descriptions > p {
	border-right: 1px solid black;
}
@media screen and (max-width: 550px) {
	.model-names > p,
	.model-descriptions > p,
	.model-image,
	.heading > p {
		padding: 2px;
	}
}

@media screen and (max-width: 425px) {
	.heading > p {
		font-size: 1em;
	}
}
