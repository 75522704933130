main {
	text-align: center;
	/* padding: 50px 100px; */
}
.card {
	display: flex;
	width: 100%;
	background-color: #0c8b23;
	/* background-color: #3d33a5; */
	/* height: 400px; */
	/* margin: 20px 0; */
}

.card > div {
	width: 50%;
}
.precautions-card {
	display: block;
	padding-bottom: 50px;
}

.precautions-card > div {
	width: 100%;
}
.precautions-card h2 {
	text-align: center;
}
.card .before-after {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 50px;
}

.card .before-after ul {
	width: 70%;
}
.card .before-after ul:first-child {
	margin-left: 30px;
}
.card .before-after ul p {
	text-decoration: underline;
}
.card-info {
	align-self: center;
	color: white;
	text-align: left;
	padding: 10px 20px;
}
.card-img {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.card-img > p {
	text-decoration: underline;
	font-weight: bold;
	margin-bottom: 30px;
	right: 20px;
}
.card-img > .reference {
	color: white;
	position: absolute;
	/* text-decoration: none; */
	font-weight: normal;
	bottom: 10px;
	right: 10px;
}
.card-img > .reference > a {
	color: white;
}

.card-info > p {
	margin: 20px 0;
}
.card .cross_section_diagram {
	display: block;
	object-fit: cover;
	width: 100%;
	/* max-height: 500px; */
	height: 100%;
}
.patient_image {
	max-width: 400px;
	margin: 5px auto;
}

.patient_image:last-of-type {
	margin: 30px auto;
}
.card .cross_section {
	width: 65%;
	height: auto;
	margin: auto;
	margin-bottom: 20px;
}

@media screen and (max-width: 900px) {
	.card {
		flex-direction: column;
		align-items: center;
	}
	.card > div {
		width: 100%;
	}

	.card-img {
		order: 1;
	}
	.card-img > p {
		position: static;
		/* right: 20px; */
		margin-top: 0px;
	}
	.card .before-after {
		grid-template-columns: 1fr;
	}
}
