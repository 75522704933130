footer {
	background-color: #323232;
	color: white;
	padding: 20px 40px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	line-height: 2;

	/* margin-top: 100px; */
}

footer .indication {
	color: gray;
	grid-column: 1/-1;
	border-top: 2px solid #0c8b23;
	margin-top: 20px;
	padding-top: 10px;
	font-size: small;
}
footer div > .address > p,
footer .indication {
	line-height: 1;
}
@media screen and (max-width: 900px) {
	footer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 50px;
	}
}

@media screen and (max-width: 550px) {
	footer {
		grid-template-columns: 1fr;
	}
}
