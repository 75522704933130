:root {
	/* --landing-height: 600px; */
	--landing-height: 80vh;
}
.landing {
	margin-top: 160px;
	background-image: url("../img/landing.jpg");
	height: var(--landing-height);
	background-size: cover;
	background-position: center;
	position: relative;
}
.landing-text {
	margin: 0;
	background-color: rgba(0, 0, 0, 0.3);
	height: var(--landing-height);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 50px;
}
.landing > a > button {
	width: 200px;
	padding: 15px 20px;
	position: absolute;
	bottom: 100px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	background-color: #0c8b23;
	/* background-color: #03b164; */
	color: white;
	font-size: large;
	outline: none;
	border: none;
}
.landing > a > button:hover {
	cursor: pointer;
}

@media screen and (max-width: 750px) {
	.landing {
		margin-top: 190px;
	}
}
@media screen and (max-width: 672px) {
	.landing {
		margin-top: 245px;
		height: 65vh;
	}
	.landing-text {
		height: 65vh;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
	}
	.landing > a > button {
		bottom: 80px;
	}
}
@media screen and (max-width: 550px) {
	.landing {
		margin-top: 0px;
	}
	.landing-text {
		font-size: 30px;
	}
}
@media screen and (max-width: 536px) {
	.landing {
		/* margin-top: 225px; */
	}
}
@media screen and (max-width: 414px) {
	.landing {
		/* margin-top: 230px; */
	}
}
@media screen and (max-width: 386px) {
	.landing {
		/* margin-top: 320px; */
		height: 90vh;
	}
	.landing-text {
		height: 90vh;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 23px;
	}
	.landing > a > button {
		bottom: 70px;
	}
}
