header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	flex-flow: row wrap;
	top: 0;
	padding: 30px 20px;
	/* height: 150px; */
	/* border-bottom: 2px solid black; */
	background-color: rgba(255, 255, 255, 1);
	width: 100%;
	z-index: 2000;
	text-align: center;
}
.eudisclaimer {
	display: block;
	position: fixed;
	top: 100px;
	z-index: 200;
	background-color: white;
	margin: 0 0;
	color: red;
	padding: 20px;
	left: 0;
	right: 0;
}

header img.lenstec-logo {
	width: 100px;
}

header .flag {
	width: 50px;
	display: block;
}
header > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
}

@media screen and (max-width: 900px) {
	header {
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 20px 0px;
	}
	header > div {
		margin-top: 20px;
	}
}

@media screen and (max-width: 550px) {
	header {
		position: static;
		/* flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 20px 0px; */
	}
	header > div {
		flex-direction: column;
		gap: 5px;
		text-align: center;
		/* margin-top: 20px; */
	}
}
